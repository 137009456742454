<template>
  <v-dialog v-model="open" max-width="500px">
    <CustomAddEditComponent
      v-if="open"
      class="host-component"
      :pCardTitle="editedHost && editedHost.id ? 'Edit Host' : 'New Host'"
      :pEditedItem="editedHost"
      :pEditedItemType="HOST_ADD_EDIT_ITEM"
      :pItemParams="HOST_PARAMS"
      :pIsDialog="true"
      :pLoading="loading"
      :pDisabledParams="disabledParams"
      @onCancelled="open = false"
      @onItemCreatedOrUpdated="saveHostAndCloseDialog($event)"
    />
  </v-dialog>
</template>

<script>
import Vue from "vue";
import {
  dismissAction, logAndExtractMessage, HOST_ADD_EDIT_ITEM, HOST_PARAMS,
} from "../../utils/utils";

export default {
  name: "HostDialog",

  components: {
    CustomAddEditComponent: () => import("../custom-components/AddEditComponent.vue"),
  },

  data: () => ({
    HOST_ADD_EDIT_ITEM,
    HOST_PARAMS,

    disabledParams: [],
    editedHost: {},
    open: false,
    loading: false,
  }),

  methods: {
    openDialog(host, disabledParams = []) {
      this.disabledParams = disabledParams;
      this.editedHost = host;
      this.open = true;
    },
    async saveHostAndCloseDialog(host) {
      this.loading = true;
      try {
        await this.$store.dispatch("hostModule/addOrSaveHost", host);
        this.$emit("onAddOrSave");
        this.open = false;
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.row-combobox {
  margin-top: 0 !important;
}
</style>
